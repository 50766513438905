import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: #333;
`;

const ResultContainer = styled.div`
  margin-top: 20px;
  text-align: center;
  width: 100%;
`;

const AIButton = styled.button`
  padding: 8px 22px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const PredictButton = () => {
    const [uuid, setUserId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [careerInfo, setCareerInfo] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const newUuid = localStorage.getItem('uuid');
        setUserId(newUuid);
    }, []);

    useEffect(() => {
        const fetchCareerInfo = async () => {
            if (!uuid) return;

            setIsLoading(true);
            try {
                const response = await axios.get(`https://lumitestserver-raisec.vercel.app/ai-career-info/${uuid}`);
                if (response.data && response.data.MLrecommendedCareer) {
                    setCareerInfo(response.data);
                } else {
                    setCareerInfo(null);
                }
                setError('');
            } catch (error) {
                console.error('Error fetching career info:', error);
                setCareerInfo(null);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCareerInfo();
    }, [uuid]);

    const handlePredict = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem('uuid');
            const response = await axios.post('https://luminate-ml-server.onrender.com/ai-api/predict', { userId });
            console.log('Prediction result:', response.data);

            const career = response.data.recommended_career;
            await axios.post('https://lumitestserver-raisec.vercel.app/ai-update-career', { uuid, career });

            const updatedCareerInfo = await axios.get(`https://lumitestserver-raisec.vercel.app/ai-career-info/${uuid}`);
            setCareerInfo(updatedCareerInfo.data);//MLrecommendedCareer
            setError('');
        } catch (error) {
            console.error('Error making prediction:', error);
            setError('Failed to make prediction or update career');
        } finally {
            setIsLoading(false);
        }
    };

    const firstcaeerfromstring = (str) => {
        let career = str.split(',')[0];
        return career;
    }

    //how can i check whether the ther test have been taken or not , if not then i will not allow the user to filter the career
    //if the test has been taken then i will allow the user to filter the career


    return (
        <div className='ai-feature'>
            <AIButton onClick={handlePredict} disabled={isLoading}>
                AI filter
            </AIButton>
            {isLoading && (
                <Overlay>
                    <div>
                        <Spinner />
                        <LoadingText>Performing AI analysis...</LoadingText>
                    </div>
                </Overlay>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {careerInfo && careerInfo.MLrecommendedCareer && (
                <ResultContainer>
                    <h3>AI-Generated Career Recommendation</h3>
                    {/* <p>Recommended Career: {firstcaeerfromstring(careerInfo.MLrecommendedCareer)}</p> */}
                    <p>Recommended Careers: {careerInfo.MLrecommendedCareer}</p>
                </ResultContainer>
            )}
        </div>
    );
};

export default PredictButton;