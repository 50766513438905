import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-hot-toast";

// Styled components for the bento box design
const BentoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: rgb(248, 253, 255);
  border-radius: 20px;
`;

const BentoBox = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Header = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const BentoForm = () => {
  const [personality, setPersonality] = useState([
    { traitstitle: "", description: "", link: "" },
    { skillPreferencestitle: "", description: "", link: "" },
    { careerPreferncestitle: "", description: "", link: "" },
  ]);

  const [temperament, setTemperament] = useState([
    { traitstitle: "", description: "", link: "" },
    { skillPreferencestitle: "", description: "", link: "" },
    { careerPreferncestitle: "", description: "", link: "" },
  ]);

  const handleChange = (e, index, type, field) => {
    const { name, value } = e.target;
    const update = type === "personality" ? [...personality] : [...temperament];
    update[index][field] = value;
    type === "personality" ? setPersonality(update) : setTemperament(update);
  };

  const handleSingleSubmit = async (data, type, index) => {
    try {
      const payload = { [type]: [data] };
      await axios.post(
        `https://lumitestserver-raisec.vercel.app/save-${type}-info/${index}`,
        payload
      );
      toast.success("Data saved successfully!");
    } catch (error) {
      toast.error("Failed to save data.");
    }
  };

  const renderBoxes = (data, type) =>
    data.map((item, index) => (
      <BentoBox key={index}>
        <Header>{type === "personality" ? "Personality" : "Temperament"} Box {index + 1}</Header>
        {Object.keys(item).map((key) => (
          <InputBox key={key}>
            <label>{key}</label>
            {key === "description" ? (
              <TextArea
                name={key}
                placeholder={key}
                value={item[key]}
                onChange={(e) => handleChange(e, index, type, key)}
              />
            ) : (
              <Input
                type={key === "link" ? "url" : "text"}
                name={key}
                placeholder={key}
                value={item[key]}
                onChange={(e) => handleChange(e, index, type, key)}
              />
            )}
          </InputBox>
        ))}
        <Button onClick={() => handleSingleSubmit(item, type, index)}>Save</Button>
      </BentoBox>
    ));

  return (
    <div style={{ padding: "20px" }}>
      <h2>Additional info for traits</h2>
      <BentoWrapper>{renderBoxes(personality, "personality")}</BentoWrapper>
      <BentoWrapper>{renderBoxes(temperament, "temperament")}</BentoWrapper>
    </div>
  );
};

export default BentoForm;
