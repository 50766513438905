import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #ffffff;
  position: relative;
`;

const BackText = styled.p`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #00008b;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  color: #00008b;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const Input = styled.input`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid #87ceeb;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid #87ceeb;
  border-radius: 4px;
  min-height: 150px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 0.875rem;
  margin: 0 0 1rem;
`;

const SubmitButton = styled.button`
  background-color: ${props => (props.disabled ? '#ccc' : '#00008b')};
  color: #ffffff;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => (props.disabled ? '#ccc' : '#0000cd')};
  }
`;

const BugReportPage = () => {
  const [title, setTitle] = useState('');
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({ title: '', description: '' }); // Validation errors
  const navigate = useNavigate();

  // Get user ID
  const getUserid = () => {
    return localStorage.getItem('uuid');
  };

  //Get username
  const getUsername = () => {
    return localStorage.getItem('username');
  };

  const getEmail = () => {
    return localStorage.getItem('email');
  };

  useEffect(() => {
    const newUuid = getUserid();
    const newUsername = getUsername();
    const newEmail = getEmail();
    setUserId(newUuid);
    setUsername(newUsername);
    setEmail(newEmail);
  }, []);

  // Validation Function
  const validateInputs = () => {
    let valid = true;
    const newErrors = { title: '', description: '' };

    if (title.length < 5 || title.length > 50) {
      newErrors.title = 'Title must be 5-50 characters long.';
      valid = false;
    }

    if (description.length < 10) {
      newErrors.description = 'Description must be at least 10 characters long.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!validateInputs()) {
      toast.error('Please fix the errors before submitting.');
      return;
    }

    try {
      await axios.post('https://lumitestserver-raisec.vercel.app/api/report-bug', { username, email, title, description, status: 'pending'  });
      toast.success('Bug report submitted successfully!');
      setTitle('');
      setDescription('');

      // Navigate after 3 seconds
      setTimeout(() => {
        navigate(`/tests/${userId}`);
      }, 3000);
    } catch (error) {
      toast.error('Failed to submit bug report. Please try again.');
    }
  };

  return (
    <PageContainer>
      <BackText onClick={() => navigate(-1)}>Back</BackText>
      <Title>Report a Bug</Title>
      <p>If you have encountered a bug, please report it using the form below.</p>
      <label>Our team will look into it and get back to you as soon as possible.</label><br />
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Bug Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        {errors.title && <ErrorText>{errors.title}</ErrorText>}

        <TextArea
          placeholder="Bug Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        {errors.description && <ErrorText>{errors.description}</ErrorText>}

        <SubmitButton type="submit" disabled={!title || !description}>
          Submit Bug Report
        </SubmitButton>
      </Form>
    </PageContainer>
  );
};

export default BugReportPage;
