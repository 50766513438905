import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import styles from '../styles/AllTests.module.css';
import icon1 from '../img/test-icon1.jpg';
import icon2 from '../img/test-icon2.jpg';
import icon3 from '../img/test-icon3.jpg';
import icon4 from '../img/test-icon4.jpg';
import icon5 from '../img/test-icon5.jpg';
import icon6 from '../img/test-icon6.jpg';

function RaisecResults() {
  const [results, setResults] = useState([]); // Results should stay as an array
  const [careerList, setCareerList] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [showResults, setShowResults] = useState(false); // Default to false to toggle visibility
  const [showCareerList, setShowCareerList] = useState(true); // Default to true to show career list initially
  const [expandedCards, setExpandedCards] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if no token
    } else {
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      setId(_id);
      setEmail(email);
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStoredResults = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-raisec-results/${userId}`);
        setResults(response.data); // No need to set it to a boolean, it should stay an array
        if (response.data.length > 0) {
          setShowResults(true); // Only show results if there are data
        }
      } catch (error) {
        console.error('Error fetching stored results:', error);
      }
    };

    const fetchStoredCareers = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-career-results/${userId}`);
        setCareerList(response.data);
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    if (userId) {
      fetchStoredResults();
      fetchStoredCareers();
    }
  }, [userId]);

  const handleToggleResults = () => {
    setShowResults(!showResults);
  };

  const handleToggleCareerList = () => {
    setShowCareerList(!showCareerList);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };

  const toggleReadMore = (index) => {
    setExpandedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const icons = [icon1, icon2, icon3, icon4, icon5, icon6];

  return (
    showResults ? (
      <div className={styles.StudentResult}>
        <div className={styles.Resultback}>
          <button onClick={() => window.history.back()} className={styles.ResultbackBtn}>Back</button>
        </div>
        <div onClick={handleToggleResults} className={styles.ResultHeading}>
          <div className={styles.headingdiv}>
            <h2>Congratulations {username}! Your results are</h2>
            <p>Answer all the following questions based on your preference. Try to avoid choosing the middle answers for more accurate results.</p>
          </div>
        </div>
        <div className="results-container" id="results-container">
          <div className={styles.resultsSection}>
            {results.length === 0 ? (
              <p>No results found</p>
            ) : (
              <div className={styles.cardContainer}>
                {results.map((res, index) => (
                  <div className={styles.raisecResultsCard} key={index}>
                    <div className={styles.iconContainer}>
                      <div key={index} className={styles.resultIcon}>
                        <img src={icons[index]} alt={`icon-${index}`} />
                      </div>
                    </div>
                    <div className={styles.ResultsUpper}>
                      <p>You got</p>
                      <p><strong>{res.score}/20 {res.area}</strong></p>
                    </div>
                    <div className={styles.resultsLower}>
                      <p className={expandedCards[index] ? styles.expanded : styles.collapsed}>
                        {res.description}
                      </p>
                      <button
                        onClick={() => toggleReadMore(index)}
                        className={styles.readMoreBtn}
                      >
                        {expandedCards[index] ? 'Read less' : 'Read more'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div onClick={handleToggleCareerList} className="result-heading">
            <h2>Common careers associated with the above are:</h2>
          </div>
          {showCareerList && (
            <div className="student-career-list">
              {careerList.length === 0 ? (
                <p>No careers found</p>
              ) : (
                careerList.map((career, index) => (
                  <div className="student-career-rep" key={index}>
                    <p className="career-heading">{career.title}</p>
                    <p>What they do: {career.whatTheyDo}</p>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        <button onClick={handleDownloadPDF} className="temp-btn">Download</button>
      </div>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100vh', alignItems: 'center' }}>
        No results found
      </div>
    )
  );
}

export default RaisecResults;
