//profile page for the user

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Airequestbutton from '../components/Airequestbutton';
import styles from '../styles/AllTests.module.css';
import icon1 from '../img/test-icon1.jpg';
import icon2 from '../img/test-icon2.jpg';
import icon3 from '../img/test-icon3.jpg';
import icon4 from '../img/test-icon4.jpg';
import icon5 from '../img/test-icon5.jpg';
import icon6 from '../img/test-icon6.jpg';

function DisplayResults() {
  const [results, setResults] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [whattheydolist, setWhatTheyDoList] = useState([]);
  const [temperament, setTemperament] = useState([]);
  const [personality, setPersonality] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  // const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [expandedTemperaments, setExpandedTemperaments] = useState([]);
  const [expandedPersonality, setExpandedPersonality] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const [showCareerList, setShowCareerList] = useState(true);
  const [showTemperament, setShowTemperament] = useState(true);
  const [showPersonality, setShowPersonality] = useState(true);
  const [expandedCards, setExpandedCards] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate('/');
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      // const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      // setId(_id);
      setEmail(email);
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStoredResults = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-raisec-results/${userId}`);
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching stored results:', error);
      }
    };

    const fetchStoredCareers = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-career-results/${userId}`);
        setCareerList(response.data);
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/stored-Temperaments/${userId}`);
        setTemperament(response.data);
      } catch (error) {
        console.error('Error fetching stored temperaments:', error);
      }
    };

    const fetchStoredPersonality = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-stored-Personalities/${userId}`);
        setPersonality(response.data);
      } catch (error) {
        console.error('Error fetching stored personalities:', error);
      }
    };

    if (userId) {
      fetchStoredResults();
      fetchStoredCareers();
      fetchStoredTemperaments();
      fetchStoredPersonality();
    }
  }, [userId]);

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments];
    newExpandedTemperaments[index] = !newExpandedTemperaments[index];
    setExpandedTemperaments(newExpandedTemperaments);
  };

  const handleTogglePersonalityDescription = (index) => {
    const newExpandedPersonality = [...expandedPersonality];
    newExpandedPersonality[index] = !newExpandedPersonality[index];
    setExpandedPersonality(newExpandedPersonality);
  };

  const handleToggleResults = () => {
    setShowResults(!showResults);
  };

  const handleToggleCareerList = () => {
    setShowCareerList(!showCareerList);
  };

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament);
  };

  const handleTogglePersonality = () => {
    setShowPersonality(!showPersonality);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };

  const toggleReadMore = (index) => {
    setExpandedCards(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const rederresults = (test) => {
    navigate(`/tests/${userId}/${test}`);
  }

 const icons = [ icon1, icon2, icon3, icon4, icon5, icon6]

  return (
    <div className={styles.StudentResult}>
      <div className={styles.Resultback}>
          <button onClick={() => window.history.back()} className={styles.ResultbackBtn}>Back</button>
      </div>
      <div className={styles.ResultHeading}>

        {/* <div className={styles.headingdiv}>
        <h2>Congratulations {username}! Your results are</h2>
        <p>Answer all the following questions based on your preference. 
          Try to avoid choosing the middle answers for more accurate results.</p>
        </div> */}

      <div className={styles.ProfileIfno}>
        <div className="result-profile-img">
          <img src={profile} alt="profile" />
          <h2>{email}</h2>
        </div>

        <div className="result-profile-inf">
        <h2>{email}</h2>
        <p>ID: lum-{userId}-nate</p>
        <h2>{username}</h2>
        </div>
      </div>

      </div>


      <div className="test-cards">
        <div className="test-card">
          <img src={icon1} alt="Raisec Test" className="test-card-img" />
          <h3>Raisec Test Results</h3>
          {results.slice(0, 3).map((res, index) => (
            <strong key={index}>{index + 1}. {res.area}</strong>
          ))}

          <button className="test-res-button" onClick={() => rederresults('raisec-test')}>Explore results</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>

        <div className="test-card">
          <img src={icon2} alt="Personality Test" className="test-card-img" />
          <h3>Personality Test Results</h3>
          {personality.map((perso, index) => (
            <strong key={index}>{perso.personalityName}</strong>
          ))}
          <button className="test-res-button" onClick={() =>  rederresults('personality-test')}>Explore results</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>

        <div className="test-card">
          <img src={icon3} alt="Temperament Test" className="test-card-img" />
          <h3>Temperament Test Results</h3>
          {temperament.map((temp, index) => (
            <strong key={index}>{temp.temperamentName}</strong>
          ))}
          <button className="test-res-button" onClick={() =>  rederresults('temperament-test')}>Explore results</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>
      </div>

        {/* <div className="results-container" >
      <div className={styles.resultsSection}>
            {results.length === 0 ? (
              <p>No results found</p>
            ) : (
              <div className={styles.cardContainer}>
                {results.map((res, index) => (
                  <div className={styles.raisecResultsCard} key={index}>
                    <div className={styles.iconContainer}>
                        <div key={index} className={styles.resultIcon}>
                          <img src={icons[index]} alt={`icon-${index}`} />
                        </div>
                    </div>
                    <div className={styles.ResultsUpper}>
                      <p>You got</p>
                      <p><strong>{res.score}/20 {res.area}</strong></p>
                    </div>
                    <div className={styles.resultsLower}>
                      <p className={expandedCards[index] ? styles.expanded : styles.collapsed}>
                        {res.description}
                      </p>
                      <button 
                        onClick={() => toggleReadMore(index)}
                        className={styles.readMoreBtn}
                      >
                        {expandedCards[index] ? 'Read less' : 'Read more'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
       

          <div onClick={handleToggleCareerList} className="result-heading">
            <h2>Common careers associated with the above are:</h2>
          </div>
          {showCareerList && (
              <div className="student-career-list">
              {careerList.length === 0 ? (
                <p>No careers found</p>
              ) : (
                careerList.map((career, index) => (
                  <div className="student-career-rep" key={index}>
                    <p className="career-heading">{career.title}</p>
                    <p>What they do: {career.whatTheyDo}</p>
                  </div>
                ))
              )}
            </div>
          )}

          <div onClick={handleToggleTemperament} className="result-heading">
            <h2>Student Temperament</h2>
          </div>
          {showTemperament && (
            <div className="student-temperament-list">
              {temperament.map((temp, index) => (
                <div className="student-temperament-rep" key={index}>
                  <h3 onClick={() => handleToggleDescription(index)}>{index + 1}. {temp.temperamentName}</h3>
                  {expandedTemperaments[index] && <p>{temp.description}</p>}
                </div>
              ))}
            </div>
          )}

          <div onClick={handleTogglePersonality} className="result-heading">
            <h2>Student Personality</h2>
          </div>
          {showPersonality && (
            <div className="student-temperament-list">
              {personality.map((perso, index) => (
                <div className="student-temperament-rep" key={index}>
                  <h3 onClick={() => handleTogglePersonalityDescription(index)}>{index + 1}. {perso.personalityName}</h3>
                  {expandedPersonality[index] && <p>{perso.description}</p>}
                </div>
              ))}
            </div>
          )}
        </div> */}
      
      {/* <div className='ai-download-btn'>
        <button onClick={handleDownloadPDF} className='dwlbtn'>Download</button>
      </div> */}
      <Airequestbutton />
      
    </div>
  );
}

export default DisplayResults;
