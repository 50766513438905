import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Onet from './Pages/OnetTest';
import Personality from './Pages/PersonalityTest';
import Temperament from './Pages/TemperamentTest';
import DisplayResults from './Pages/DisplayResults';
import Error from './components/Error';
import RaisecResults from './Pages/RaisecResults';
import TemperamentResults from './Pages/TemperamentResults';
import PersonalityResults from './Pages/PersonalityResults';
import AdditionalInfo from './Pages/AdditionalInfoForm';
import BugFixes from './Pages/BugFixes';
import BugReportAdmin from './Pages/BugReportAdmin';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Tests from './Pages/Tests';
import { Toaster } from 'react-hot-toast';
import Instruct from './Pages/Instruct';
import './App.css';

// Function to check if user is logged in
const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  return token !== null;
};

// Higher-order component to protect routes
const PrivateRoute = ({ element }) => {
  useEffect(() => {
    if (!isLoggedIn()) {
      window.location.href = '/';
    }
  }, []);

  return isLoggedIn() ? element : null;
};

const App = () => {
  return (
    <Router>
      <div>
        <Toaster position='top-center' toastOptions={{ duration: 2000 }} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/tests/:uuid" element={<PrivateRoute element={<Tests />} />} />
          <Route path="/report-bugs" element={<PrivateRoute element={<BugFixes />} />} />
          <Route path="/bug-reports" element={<PrivateRoute element={<BugReportAdmin />} />} />
          <Route path="/instructions" element={<PrivateRoute element={<Instruct />} />} />
          <Route path="/raisec-test" element={<PrivateRoute element={<Onet />} />} />
          <Route path="/personality-test" element={<PrivateRoute element={<Personality />} />} />
          <Route path="/temperament-test" element={<PrivateRoute element={<Temperament />} />} />
          <Route path="/additional-info" element={<PrivateRoute element={<AdditionalInfo />} />} />
          <Route path="/general-results" element={<PrivateRoute element={<DisplayResults />} />} />
          <Route path="/raisec-test-results" element={<PrivateRoute element={<RaisecResults />} />} />
          <Route path="/temperament-test-results" element={<PrivateRoute element={<TemperamentResults />} />} />
          <Route path="/personality-test-results" element={<PrivateRoute element={<PersonalityResults />} />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
