import React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../img/test-icon1.png';
import icon2 from '../img/test-icon2.png';
import icon3 from '../img/test-icon3.png';
import BugFixbtn from '../components/BugFixBtn';
import BugReports from '../components/BugReportsbtn';
import AdminWrapper from '../components/AdminWrapper';
import Version from '../components/Version';
import '../styles/Tests.css';

const TestsAvailable = ({ handleTestClick }) => {
  return (
    <div className="tests-available">
      <h2 className="section-title">SELECT A TEST TO GET STARTED</h2>
      <div className="test-cards">
        <div className="test-card">
          <img src={icon1} alt="Raisec Test" className="test-card-img" />
          <h3>Raisec Test</h3>
          <p>Discover your career inclinations based on interests and skills.</p>
          <button className="test-button" onClick={() => handleTestClick('raisec-test')}>Take Test</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>

        <div className="test-card">
          <img src={icon2} alt="Personality Test" className="test-card-img" />
          <h3>Personality Test</h3>
          <p>Find out more about your personality traits.</p>
          <button className="test-button" onClick={() => handleTestClick('personality-test')}>Take Test</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>

        <div className="test-card">
          <img src={icon3} alt="Temperament Test" className="test-card-img" />
          <h3>Temperament Test</h3>
          <p>Understand your temperament to enhance personal growth.</p>
          <button className="test-button" onClick={() => handleTestClick('temperament-test')}>Take Test</button>
          <Link to="#" className="read-more">Read More</Link>
        </div>
      </div>
      <AdminWrapper>
        <BugReports />
      </AdminWrapper>
      <Version />
      <BugFixbtn />
    </div>
  );
};

export default TestsAvailable;