import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AdminWrapper from '../components/AdminWrapper';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f0f8ff;
`;

const Title = styled.h1`
  color: #00008b;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  color: #00008b;
  margin-bottom: 0.5rem;
`;

const CardStatus = styled.span`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: ${props => props.status === 'pending' ? '#ffd700' : '#90ee90'};
  color: ${props => props.status === 'pending' ? '#8b4513' : '#006400'};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
`;

const ResolveButton = styled(Button)`
  background-color: #4caf50;
  color: white;
`;

const DeleteButton = styled(Button)`
  background-color: #f44336;
  color: white;
`;

const BugReportAdmin = () => {
  const [bugReports, setBugReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBugReports();
  }, []);

  const fetchBugReports = async () => {
    try {
      const response = await axios.get('https://lumitestserver-raisec.vercel.app/api/bug-reports');
      setBugReports(response.data);
    } catch (error) {
      toast.error('Failed to fetch bug reports');
    }
  };

  const handleCardClick = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const handleResolve = async () => {
    if (!selectedReport) return;
    try {
      await axios.patch(`https://lumitestserver-raisec.vercel.app/api/bug-reports/${selectedReport._id}/resolve`);
      toast.success('Bug report resolved successfully');
      setIsModalOpen(false);
      fetchBugReports();
    } catch (error) {
      toast.error('Failed to resolve bug report');
    }
  };

  const handleDelete = async () => {
    if (!selectedReport) return;
    try {
      await axios.delete(`https://lumitestserver-raisec.vercel.app/api/bug-reports/${selectedReport._id}`);
      toast.success('Bug report deleted successfully');
      setIsModalOpen(false);
      setIsDeleteConfirmOpen(false);
      fetchBugReports();
    } catch (error) {
      toast.error('Failed to delete bug report');
    }
  };

  return (
    <AdminWrapper>
    <PageContainer>
      <Title>Bug Reports</Title>
      <Grid>
        {bugReports.map(report => (
          <Card key={report._id} onClick={() => handleCardClick(report)}>
            <CardTitle>{report.title}</CardTitle>
            <CardStatus status={report.status}>{report.status}</CardStatus>
          </Card>
        ))}
      </Grid>
      {isModalOpen && selectedReport && (
        <Modal>
          <ModalContent>
            <h2>{selectedReport.title}</h2>
            <p><strong>Status:</strong> {selectedReport.status}</p>
            <p><strong>Reported by:</strong> {selectedReport.username}</p>
            <p><strong>Email:</strong> {selectedReport.email}</p>
            <p><strong>Created at:</strong> {new Date(selectedReport.createdAt).toLocaleString()}</p>
            {selectedReport.resolvedAt && (
              <p><strong>Resolved at:</strong> {new Date(selectedReport.resolvedAt).toLocaleString()}</p>
            )}
            <p><strong>Description:</strong> {selectedReport.description}</p>
            {selectedReport.status === 'pending' && (
              <ResolveButton onClick={handleResolve}>Resolve</ResolveButton>
            )}
            <DeleteButton onClick={() => setIsDeleteConfirmOpen(true)}>Delete</DeleteButton>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </ModalContent>
        </Modal>
      )}
      {isDeleteConfirmOpen && (
        <Modal>
          <ModalContent>
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this bug report?</p>
            <DeleteButton onClick={handleDelete}>Yes, Delete</DeleteButton>
            <Button onClick={() => setIsDeleteConfirmOpen(false)}>Cancel</Button>
          </ModalContent>
        </Modal>
      )}
    </PageContainer>
    </AdminWrapper>
  );
};

export default BugReportAdmin;