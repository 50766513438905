import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import { toast } from 'react-hot-toast';
import loginSideImage from '../img/login-image.jpg';
import registerSideImage from '../img/login-image.jpg';
import styled from 'styled-components';
import logo from '../img/kaeval-logo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { auth, db } from '../components/Auth/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, fetchSignInMethodsForEmail } from 'firebase/auth';
import { setDoc, doc} from 'firebase/firestore';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const CopyrightText = styled.div`
  position: absolute;
  bottom: 20px;
  color:rgb(255, 255, 255);
  font-size: 12px;
  z-index: 3;
  padding: 2px 5px;
  border-radius: 3px;

  @media (max-width: 628px) {
    display: none;
  }
`;

const Copyrightdiv = styled.div`
  width: 100%;
  padding-left: 20px;
  `;

const SibaSpan = styled.span`
  color: #3fa4fd;
`

// Utility functions for local storage management
const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  localStorage.setItem(key, value);
  localStorage.setItem(`${key}_expiry`, now.getTime() + ttl);
};

const getWithExpiry = (key) => {
  const expiryStr = localStorage.getItem(`${key}_expiry`);
  if (!expiryStr) {
    return null;
  }
  const now = new Date();
  if (now.getTime() > parseInt(expiryStr, 10)) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}_expiry`);
    return null;
  }
  return localStorage.getItem(key);
};

const Login = () => {
  // Separate state for login and register forms
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const [registerData, setRegisterData] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreement: false
  });

  const [fade, setFade] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subdomain, setSubdomain] = useState('luminate');
  const navigate = useNavigate();
  const currentDate = new Date().getFullYear();

  const isLoggedIn = () => {
    const token = getWithExpiry('token');
    return token !== null;
  };

  useEffect(() => {
    const currentUrl = window.location.hostname;
    const match = currentUrl.match(/^[^.]+/);
    const extractedSubdomain = match ? match[0] : 'Luminate';
    setSubdomain(extractedSubdomain);
  }, []);

  useEffect(() => {
    const uuid = getWithExpiry('uuid');
    if (isLoggedIn()) {
      toast.error('You are already logged in');
      navigate(`/tests/${uuid}`);
    }
  }, [navigate]);

  // Update handler for login form
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value
    });
  };

  // Update handler for register form
  const handleRegisterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegisterData({
      ...registerData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handlenavtoluminate = () => {
    window.location.href = 'https://luminatecs.com';
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      // Attempt to log in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginData.email,
        loginData.password
      );
      const user = userCredential.user;
  
      console.log('User logged in:', user);

      // Check if the email exists and provider mismatch
      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email);

      if (signInMethods.length > 0 && !signInMethods.includes('password')) {
        // Email exists but was registered with email/password, throw error
        toast.error(
          'Email already registered with a different method. Please log in using Google or register a new account.'
        );
        setLoading(false);
        return;
      }
  
      // Get JWT token
      const jwtoken = await user.getIdToken();
  
      // Store user data in localStorage with expiry
      setWithExpiry('username', user.displayName || 'User', 1209600000);
      setWithExpiry('token', jwtoken, 1209600000);
      setWithExpiry('email', user.email, 1209600000);
      setWithExpiry('uuid', user.uid, 1209600000);
  
      // Success message and redirection
      toast.success('Login successful! Redirecting...');
      setTimeout(() => {
        navigate(`/tests/${user.uid}`);
      }, 3000);
    } catch (error) {
      console.error('Firebase login error:', error);
  
      // Handle specific error codes
      switch (error.code) {
        case 'auth/invalid-email':
          toast.error('Invalid email format. Please check your email address.');
          break;
  
        case 'auth/user-disabled':
          toast.error('This account has been disabled. Contact support.');
          break;
  
        case 'auth/user-not-found':
          toast.error('No user found with this email. Please register first.');
          break;
  
        case 'auth/wrong-password':
          toast.error('Incorrect password. Please try again.');
          break;
  
        case 'auth/too-many-requests':
          toast.error('Too many failed attempts. Please try again later.');
          break;
  
        case 'auth/network-request-failed':
          toast.error('Network error. Check your internet connection.');
          break;
  
        default:
          // Generic error message for unexpected cases
          toast.error(error.message || 'Login failed. Please try again.');
          break;
      }
    } finally {
      // Ensure loading is always set to false at the end
      setLoading(false);
    }
  };
  
  
  
  // const handleLogin = async () => {
  //   setLoading(true); 
  //   try {
  //     const response = await axios.post('https://lumitestserver-raisec.vercel.app/login/', {
  //       email: loginData.email,
  //       password: loginData.password
  //     });
  //     const { displayName, token, _id, email, message, uuid } = response.data;

  //     setWithExpiry('displayName', displayName, 1209600000);
  //     setWithExpiry('token', token, 1209600000);
  //     setWithExpiry('_id', _id, 1209600000);
  //     setWithExpiry('email', email, 1209600000);
  //     setWithExpiry('uuid', uuid, 1209600000);

  //     toast.success(message);
  //     setLoginData({ email: '', password: '' });

  //     setTimeout(() => {
  //       navigate(`/tests/${uuid}`);
  //     }, 3000);
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     toast.error('An error occurred. Please try again.');
  //   } finally {
  //       setLoading(false); // Set loading to false when done
  //     }
  // };


  const handleRegister = async () => {
    // Validation checks
    if (!registerData.agreement) {
      toast.error('You must agree to the terms and conditions to register.');
      return;
    }
    if (registerData.displayName.length < 8) {
      toast.error('Display name must be at least 8 characters long.');
      return;
    }
    if (registerData.password.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      return;
    }
    if (registerData.password !== registerData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    if (!/\S+@\S+\.\S+/.test(registerData.email)) {
      toast.error('Invalid email format.');
      return;
    }
  
    setLoading(true);
  
    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerData.email,
        registerData.password
      );
  
      const user = userCredential.user;
  
      // Update profile with displayName
      await updateProfile(user, {
        displayName: registerData.displayName,
      });
  
      // Save user details to Firestore database
      await setDoc(doc(db, 'users', user.uid), {
        displayName: registerData.displayName,
        email: registerData.email,
        uuid: user.uid,
        createdAt: new Date().toISOString(),
      });
  
      // Sync data with external server
      await axios.post('https://lumitestserver-raisec.vercel.app/backup-register/', {
        username: registerData.displayName,
        email: registerData.email,
        password: registerData.password,
        uuid: user.uid, // Ensure backend accepts this uid instead of dynamically creating one
      });
  
      console.log('User registered:', user);
  
      // Display success message
      toast.success('Registration successful! Redirecting...');
      setTimeout(() => {
        navigate('/');
      }, 3000);
  
      // Reset form fields
      setRegisterData({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreement: false,
      });
    } catch (error) {
      console.error('Registration failed:', error);
  
      // Handle specific error codes
      switch (error.code) {
        case 'auth/email-already-in-use':
          toast.error('This email is already registered. Try logging in instead.');
          break;
  
        case 'auth/invalid-email':
          toast.error('Invalid email format. Please check and try again.');
          break;
  
        case 'auth/weak-password':
          toast.error('Password is too weak. Please use a stronger password.');
          break;
  
        case 'auth/network-request-failed':
          toast.error('Network error. Check your internet connection and try again.');
          break;
  
        case 'auth/too-many-requests':
          toast.error('Too many requests. Please wait and try again later.');
          break;
  
        case 'auth/operation-not-allowed':
          toast.error('Registration is disabled. Please contact support.');
          break;
  
        default:
          toast.error(error.message || 'An error occurred. Please try again.');
          break;
      }
    } finally {
      // Ensure loading indicator is turned off
      setLoading(false);
    }
  };
  
  

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
  
    try {
      // Prompt Google Sign-in
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Check if the email is already registered with a different provider
      const signInMethods = await fetchSignInMethodsForEmail(auth, user.email);
  
      if (signInMethods.length > 0 && !signInMethods.includes('google.com')) {
        toast.error(
          `This email is already registered using ${
            signInMethods[0] === 'password' ? 'Email and Password' : signInMethods[0]
          }. Please log in with your original method.`
        );
        setLoading(false);
        return; // Stop further execution
      }
  
      // Continue login if the email is new or matches Google
      const userDoc = doc(db, 'users', user.uid);
      await setDoc(
        userDoc,
        {
          displayName: user.displayName,
          email: user.email,
          uuid: user.uid,
          provider: 'google',
          createdAt: new Date().toISOString(),
        },
        { merge: true }
      );
  
      // Check if user exists and if they are an admin
      const backupCheck = await axios.get(
        `https://lumitestserver-raisec.vercel.app/check-user/${user.email}`
      );
  
      if (!backupCheck.data.exists) {
        await axios.post('https://lumitestserver-raisec.vercel.app/backup-register/', {
          username: user.displayName,
          email: user.email,
          uuid: user.uid,
        });
      }
  
      // Save user data locally
      const jwtoken = await user.getIdToken();
      setWithExpiry('username', user.displayName || 'User', 1209600000);
      setWithExpiry('token', jwtoken, 1209600000);
      setWithExpiry('email', user.email, 1209600000);
      setWithExpiry('uuid', user.uid, 1209600000);
  
      // Store admin flag if true
      if (backupCheck.data.admin) {
        setWithExpiry('admin', 'true', 1209600000);
      }
  
      toast.success('Google login successful! Redirecting...');
      setTimeout(() => {
        navigate(`/tests/${user.uid}`);
      }, 3000);
  
    } catch (error) {
      console.error('Google login error:', error);
  
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          toast.error('Popup closed before login was completed.');
          break;
  
        case 'auth/network-request-failed':
          toast.error('Network error. Check your connection and try again.');
          break;
  
        default:
          toast.error(error.message || 'Google login failed. Please try again.');
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  


  // const handleRegister = async () => {
  //   if (!registerData.agreement) {
  //     toast.error('You must agree to the terms and conditions to register.');
  //     return;
  //   }
  //   if (registerData.displayName.length < 8) {
  //     toast.error('displayName must be at least 8 characters long.');
  //     return;
  //   }
  //   if (registerData.password.length < 8) {
  //     toast.error('Password must be at least 8 characters long.');
  //     return;
  //   }
  //   if (registerData.password !== registerData.confirmPassword) {
  //     toast.error('Passwords do not match.');
  //     return;
  //   }

  //   setLoading(true); 
  //   try {
  //     const response = await axios.post('https://lumitestserver-raisec.vercel.app/register/', {
  //       username: registerData.displayName,
  //       email: registerData.email,
  //       password: registerData.password
  //     });
  //     toast.success('Registration successful');
  //     setTimeout(() => {
  //       navigate('/');
  //     }, 3000);
  //     setRegisterData({ displayName: '', email: '', password: '', confirmPassword: '', agreement: false });
  //   } catch (error) {
  //     console.error('Registration failed:', error);
  //     toast.error('An error occurred. Please try again.');
  //   } finally {
  //       setLoading(false);
  //     }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      await handleRegister();
    } else {
      await handleLogin();
    }
  };

  const handleToggleForm = () => {
    setFade(true);
    setTimeout(() => {
      setIsRegistering(!isRegistering);
      setFade(false);
    }, 500);
  };

  const isLoginButtonDisabled = !loginData.email || !loginData.password;
  const isRegisterButtonDisabled = !registerData.displayName || !registerData.email || !registerData.password || !registerData.confirmPassword || !registerData.agreement;

  return (
    <div className={`outer-login-container ${fade ? 'fade-out' : 'fade-in'}`}>
      <div className={`login-container ${isRegistering ? 'register-mode' : ''}`}>
        <div className="left-section">
          <img
            src={isRegistering ? registerSideImage : loginSideImage} 
            alt="Side Imag"
            className="login-image"
          />
          {/* <LiveSearch /> */}
          <Copyrightdiv>
          <CopyrightText>© {currentDate} kaeval <SibaSpan style={{cursor: 'pointer'}} onClick={handlenavtoluminate}>Luminatecs</SibaSpan></CopyrightText>
          </Copyrightdiv>
        </div>
        <div className="right-outer-section">
          <div className="right-section">
            <form className="login-form" onSubmit={handleSubmit}>
              <h2>
                {isRegistering ? (<> <strong>Register</strong> </> ) : (<> <strong>Log</strong> In </> )} To {subdomain} 
              </h2>

              {isRegistering ? (
                <div className='reg-side'>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="username"
                      name="displayName"
                      value={registerData.displayName}
                      onChange={handleRegisterChange}
                    />
                  </div>
                  <div className="input-container">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={registerData.email}
                      onChange={handleRegisterChange}
                    />
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={registerData.password}
                        onChange={handleRegisterChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={registerData.confirmPassword}
                        onChange={handleRegisterChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <div className="agreement-section">
                    <input
                      type="checkbox"
                      id="agreement"
                      name="agreement"
                      checked={registerData.agreement}
                      onChange={handleRegisterChange}
                    />
                    <label htmlFor="agreement" className='agreementp'>
                      By creating an account, you agree to Luminate's <Link className="terms-link" to="/legal">Terms of Services and Privacy Policy</Link>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={loginData.email}
                      onChange={handleLoginChange}
                    />
                  </div>
                  <div className="input-container">
                    <div className="password-wrapper">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={loginData.password}
                        onChange={handleLoginChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                </>
              )}

              <button type="submit" className="login-button"  disabled={isRegistering ? isRegisterButtonDisabled : isLoginButtonDisabled}>
                {loading ? (
                  <div className="spinner"></div>
                ) : isRegistering ? (
                  'Register'
                ) : (
                  'Login'
                )}
              </button>

              <p className="noregtag">
                {isRegistering ? 'Already have an account? ' : "Don't have an account? "}{" "}
                <span className="noreg" onClick={handleToggleForm}>
                  {isRegistering ? 'Login' : 'Register'}
                </span>
              </p>
              <div className="divider">- or -</div>
              <button onClick={handleGoogleLogin} className="social-button google-button">
                {!isRegistering ? 'Login' : 'Register'} with Google
              </button>
              {/* <button disabled className="social-button apple-button">
                {!isRegistering ? 'Login' : 'Register'} with Apple
              </button> */}
              <div className="kaeval-logo-image">
                <img src={logo} alt="logo" />
              </div>
            </form>
          </div>
          <div className="other-section"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
