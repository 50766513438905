import React, { useState } from 'react';
import styled from 'styled-components';
import { Gift, X } from 'lucide-react';
// import { useAtom } from 'jotai';
// import { currentVersion } from '../GlobalJotaiStates';

const OuterContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 1.2rem;
  z-index: 50;
`;

const VersionInfoContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--version-bg-color);
  color: var(--version-text-color);
  border-radius: 0.5rem;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  width: ${props => (props.$isExpanded ? 'auto' : '1.8rem')};
  height: ${props => (props.$isExpanded ? 'auto' : '1.8rem')};
  padding: ${props => (props.$isExpanded ? '0.3rem 1rem' : '0')};


  @media (max-width: 768px) {
    color: white;
  }
`;

const VersionText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  margin: 0;
`;

const IconButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 0;
`;

const CloseButton = styled(IconButton)`
  margin-left: 0.5rem;
`;

const VersionSpan = styled.span`
  color: blue;
  cursor: pointer;
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 0.2rem;
  right: calc(100% + 1rem);
  transform: translateY(-50%);
  background-color: var(--version-tooltip-bg-color);
  color: var(--version-tooltip-txt-color);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
`;

const TooltipContainer = styled.div`
  position: relative;

  &:hover ${Tooltip} {
    opacity: 1;
  }
`;

const VersionInfo = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const version = '1.0.0-beta.1';

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleVersionClick = () => {
    console.log('Open version details page');
  };

  return (
    <OuterContainer>
      <TooltipContainer>
        <VersionInfoContainer $isExpanded={isExpanded}>
          {isExpanded ? (
            <>
              <VersionText>
                <span>version </span>
                <VersionSpan onClick={handleVersionClick}>{version}</VersionSpan>
              </VersionText>
              <CloseButton onClick={handleToggle} aria-label="Close version info">
                <X size={16} />
              </CloseButton>
            </>
          ) : (
            <IconButton onClick={handleToggle} aria-label="Show version info">
              <Gift size={16} />
            </IconButton>
          )}
        </VersionInfoContainer>
        {!isExpanded && <Tooltip>What's new</Tooltip>}
      </TooltipContainer>
    </OuterContainer>
  );
};

export default VersionInfo;
