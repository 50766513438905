import React, { useState, useEffect } from 'react';
import axios from 'axios';
import questionsData from '../components/personality-questions.json';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import '../styles/tp.css';
import '../styles/Temperament.css';
import luminateLogo from '../img/luminate-logo.png';

const PersonalityTest = () => {
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  const getUserid = () => {
    return localStorage.getItem('uuid');
  };

  useEffect(() => {
    const newUuid = getUserid();
    setUserId(newUuid);
  }, []);

  useEffect(() => {
    setLoading(true);
    setQuestions(questionsData);
    setLoading(false);
  }, []);

  const handleAnswerSelect = (questionIndex, selectedOption) => {
    setAnswers({ ...answers, [questionIndex]: selectedOption });
  };

  const submitForm = async () => {
    try {
      await axios.post(`https://lumitestserver-raisec.vercel.app/p-submit-answers/${userId}`, { answers });
      toast.success('Submission Successful');
      setTimeout(() => {
        navigate(`/tests/${userId}`);
    }, 3000);
    } catch (error) {
      toast.error('Error submitting test:', error);
    }
  };

  if (loading) return (
    <div className='loader'>
      <svg className="loader" width="200" height="200" viewBox="0 0 240 240">
        <circle className="loader-ring loader-ring-a" cx="120" cy="120" r="105" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 660" strokeDashoffset="-330" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-b" cx="120" cy="120" r="35" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 220" strokeDashoffset="-110" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-c" cx="85" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
        <circle className="loader-ring loader-ring-d" cx="155" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeLinecap="round"></circle>
      </svg>
    </div>
  );

  return (
    <div>
      <a href={`/tests/${userId}`} className="raisec-link">
      <div className="r-back-button">
            {/* <img src={luminateLogo} alt="profile" className="logo-image" /> */}
            <p>Back</p>
          </div>
        </a>

    <div className='raisec-intro'>
          <h1>Interest Profiler/Raisec Test</h1>
          <p>Answer <strong>all</strong> the following questions based on your preference. Try to avoid choosing the middle answers for more accurate results.</p>
      </div>
    <div className='ttests-container'>
      <h1>Personality Test</h1>
        <form onSubmit={submitForm}>
          {questions.map((question, index) => (
            <div key={index}>
                <div className='tpersonality-question-container'>
              <h3>Question {index + 1}</h3>
              <p>{question.question}</p>
              <div className="tanswer-options">
                {question.options.map((option, optionIndex) => (
                  <label key={optionIndex} className='tanswer-option'>
                    <input
                      type="radio"
                      name={`question_${index}`}
                      value={option}
                      className="square"
                      onChange={() => handleAnswerSelect(index, option)}
                      checked={answers[index] === option}
                      required
                    />
                    {option}
                  </label>
                ))}
              </div>
              </div>
            </div>
          ))}
          <button className='temp-btn' type="submit">Submit</button>
        </form>
      <p style={{fontSize: "8px"}}>The Myers-Briggs Type Indicator MBTI</p>
    </div>
    </div>
  );
};

export default PersonalityTest;
