import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import profile from '../img/profile-img-grey.jpg';
import tempimg from '../img/tempimg.jpg';
import styles from '../styles/AllTests.module.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import handleTestClick from './Tests';


function PersonalityResults() {
  const [personality, setTemperament] = useState([]);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [_id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [expandedTemperaments, setExpandedTemperaments] = useState([]);
  const [showTemperament, setShowTemperament] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if user is not logged in
      navigate('/');
    } else {
      // Fetch user ID from session storage
      const newUuid = localStorage.getItem('uuid');
      const username = localStorage.getItem('username');
      const _id = localStorage.getItem('_id');
      const email = localStorage.getItem('email');
      setUserId(newUuid);
      setUsername(username);
      setId(_id);
      setEmail(email);
    }
  }, [navigate]);
  

  useEffect(() => {
    const fetchStoredTemperaments = async () => {
      try {
        const response = await axios.get(`https://lumitestserver-raisec.vercel.app/p-stored-Personalities/${userId}`);
        setTemperament(response.data);
        if (response.data.length > 0) {
          setShowTemperament(true);
        }
      } catch (error) {
        console.error('Error fetching stored careers:', error);
      }
    };

    if (userId) {
      fetchStoredTemperaments();
    }
  }, [userId]);

  const handleToggleDescription = (index) => {
    const newExpandedTemperaments = [...expandedTemperaments];
    newExpandedTemperaments[index] = !newExpandedTemperaments[index];
    setExpandedTemperaments(newExpandedTemperaments);
  };

  const handleToggleTemperament = () => {
    setShowTemperament(!showTemperament);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById('results-container');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`results-${username}.pdf`);
    });
  };
  return (
    showTemperament ? (
      <div className="Student-result">
        <div className={styles.Resultback}>
          <button
            onClick={() => window.history.back()}
            className={styles.ResultbackBtn}
          >
            Back
          </button>
        </div>
        <div
          onClick={handleToggleTemperament}
          className={styles.ResultHeading}
        >
          <div className={styles.headingdiv}>
            <h2>Congratulations {username}! Your results are</h2>
            <p>
              Answer all the following questions based on your preference. Try to
              avoid choosing the middle answers for more accurate results.
            </p>
          </div>
          {/* <div className="result-profile-img">
             <img src={profile} alt="profile" />
           </div>
           <h3>{username}</h3>
           <p>ID: lum-{_id}-nate</p>
           <p>{email}</p> */}
        </div>
  
        <div className={styles.studentTemperamentList}>
          <div className={styles.studentTemperamentcards}>
            <div className={styles.uppercard}>
              <div className={styles.uppercardleft}>
                {personality.map((perso, index) => (
                  <div
                    className={styles.tempdescription}
                    key={index}
                  >
                    <h2
                      onClick={() =>
                        handleToggleDescription(index)
                      }
                    >
                      {perso.personalityName}
                    </h2>
                    <div
                      className={styles.descriptioninnerbox}
                    >
                      <p>{perso.description}</p>
                      <button
                        className={styles.startestbtn}
                        onClick={() =>
                          navigate("/personality-test")
                        }
                      >
                        Start Test
                      </button>
                    </div>
                  </div>
                ))}
              </div>
  
              <div className={styles.uppercardright}>
                <img
                  className={styles.tempprofileimg}
                  src={tempimg}
                  alt="profile"
                />
              </div>
            </div>
  
            <div className={styles.lowercard}>
              <div className={styles.card}>
                <h3>Personality 1</h3>
                <p>Personality 1 description</p>
                <button className={styles.startestbtn}>
                  Start Test
                </button>
              </div>
  
              <div className={styles.card}>
                <h3>Personality 2</h3>
                <p>Personality 2 description</p>
                <button className={styles.startestbtn}>
                  Start Test
                </button>
              </div>
  
              <div className={styles.card}>
                <h3>Personality 3</h3>
                <p>Personality 3 description</p>
                <button className={styles.startestbtn}>
                  Start Test
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={handleDownloadPDF}
            className="temp-btn"
          >
            Download
          </button>
        </div>
      </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100vh', alignItems: 'center' }}>
        No results found
        </div>
      )
      );
}

export default PersonalityResults;