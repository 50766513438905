import React from 'react'
import styled from 'styled-components'
import { Bug } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'; 

const StyledLink = styled(Link)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: none;
    ring: 2px;
    ring-offset: 2px;
    ring-color: #3fa4fd;
  }
`

const ReportBugButton = () => {
  return (
    <StyledLink to="/report-bugs">
      <Bug size={18} />
      <span>Report Bug</span>
    </StyledLink>
  )
}

export default ReportBugButton
